import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { DownloadComponent } from './download/download.component';
import { FeatureComponent } from './feature/feature.component';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DownloadFileComponent } from './download/downloadfile.component';


import { LangModalComponent } from './modal/langmodal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    DownloadComponent,
    DownloadFileComponent,
    FeatureComponent,
    LangModalComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    NgbModule,
    NgbModalModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'apk/:id', component: DownloadFileComponent },
      { path: 'download', component: DownloadComponent },
      { path: 'businesstype/:id', component: HomeComponent },
      { path: 'feature', component: FeatureComponent },
      { path: ':id', component: HomeComponent },
      { path: ':id/:id2', component: HomeComponent },
    ])
  ],
  entryComponents: [LangModalComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
