import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as uuid from 'uuid';
import Swal from 'sweetalert2';
//import * as moment from 'moment'

@Component({
  selector: 'langmodal-modal',
  templateUrl: './langmodal.component.html',
})
export class LangModalComponent implements OnInit {
  @Input() public lang;


  constructor(private http: HttpClient, private modalService: NgbModal, private formBuilder: FormBuilder, public activeModal: NgbActiveModal) {

  }
  ngOnInit(): void {


  }

  closeModal() {
    this.modalService.dismissAll();
  }

  langselect(value) {
    this.activeModal.close(value);
  }

}
