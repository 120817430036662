import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/core';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formDownload } from '../_models/formDownload';
import { JsonPages } from '../_models/JsonPages';
import { Pages } from '../_models/Pages';
import { PagesMenus } from '../_models/PagesMenus';
import Swal from 'sweetalert2';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-download',
  templateUrl: './downloadfile.component.html',
})
export class DownloadFileComponent {
  message: string;

  constructor(http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private modalService: NgbModal, @Inject('BASE_URL') baseUrl: string) {
    var id = this.route.snapshot.paramMap.get('id');

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    http.get<string>(baseUrl + 'api/download/get/' + id, { headers: headers, params: null }).subscribe(result => {
      if (result == "1") {
        this.downloadAPK();
        this.message = "";
      } else {
        this.message = "Acces denied";
      }
      
    },
      error => {

      });

  }

  downloadAPK() {
    const fUrl = 'https://taladpos.com/pos.apk';
    const fName = 'TaladPos.apk';
    FileSaver.saveAs(fUrl, fName);
  }

  ngOnInit(): void {

  }
}
