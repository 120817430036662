import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/core';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { formDownload } from '../_models/formDownload';
import { JsonPages } from '../_models/JsonPages';
import { Pages } from '../_models/Pages';
import { PagesMenus } from '../_models/PagesMenus';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
})
export class DownloadComponent {
  mForm: FormGroup;
  public Pages: Pages;
  public PagesMenus: PagesMenus[];
  public url: string;
  public html: string;
  isExpanded = false;
  public _http: HttpClient;
  siteKey: string = "6LfAIaUZAAAAALrB2zeEYw9JgaHXhkPOctgKEWIZ";
  contactData: DialogData = {
    "FullName": '',
    "Email": '',
    "Phone": '',
    "Company": '',
    "g-recaptcha-response": ''
  };
  token: string;
  public id: string;
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  get f() { return this.mForm.controls; }

  constructor(http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private modalService: NgbModal, @Inject('BASE_URL') baseUrl: string) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.url = baseUrl;
    this._http = http;

    this.url = baseUrl;
    const jsonPages: JsonPages = {
      Lang: "LA",
      Code: "download",
      Pages: null,
      PagesMenus: []
    };
    
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    http.post<JsonPages>(baseUrl + 'api/pages/read', JSON.stringify(jsonPages), { headers: headers, params: null }).subscribe(result => {
      this.Pages = result.Pages;
      this.PagesMenus = result.PagesMenus;
    },
      error => {

      });

  }

  ngOnInit(): void {
    this.mForm = this.formBuilder.group({
      FullName: ['', Validators.required],
      Email: ['', Validators.required],
      Phone: ['', Validators.required],
      Company: '',
      token: ''
    });

  }

  captchaSuccess(event): void {
    this.contactData['g-recaptcha-response'] = event;
  }

  onSubmit() {
    if (this.mForm.invalid) {
      return;
    }


    var contactData: formDownload = {
      "FullName": this.f.FullName.value,
      "Email": this.f.Email.value,
      "Phone": this.f.Phone.value,
      "Company": this.f.Company.value,
      "g-recaptcha-response": this.contactData['g-recaptcha-response']
    };
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    this._http.post<string>(this.url + 'api/quote/post', JSON.stringify(contactData), { headers: headers, params: null }).subscribe(result => {
      this.mForm = this.formBuilder.group({
        FullName: ['', Validators.required],
        Email: ['', Validators.required],
        Phone: ['', Validators.required],
        Company: '',
        token: ''
      });
        Swal.fire({
      title: "Merci",
      text: "",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-success",
      },
      icon: "success"
    })

    },
      error => {

      });
  }
}
export interface DialogData {
  "FullName": string,
  "Email": string,
  "Phone": string,
  "Company": string,
  "g-recaptcha-response": string
}
