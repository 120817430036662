import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/compiler/src/core';
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LangModalComponent } from '../modal/langmodal.component';
import { JsonPages } from '../_models/JsonPages';
import { Pages } from '../_models/Pages';
import { PagesMenus } from '../_models/PagesMenus';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  public Pages: Pages;
  public PagesMenus: PagesMenus[];
  public url: string;
  public html: string;
  public http: HttpClient;
  isExpanded = false;

  public id: string;
  public id2: string;
  public privacy: string;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  constructor(private router: Router,http: HttpClient, private formBuilder: FormBuilder, private route: ActivatedRoute, private modalService: NgbModal, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
    this.http = http;
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.privacy = "ຄວາມເປັນສ່ວນຕົວ";

    if (this.id == null) {
      this.id = "home";
    }
    const jsonPages: JsonPages = {
      Lang: "LA",
      Code: this.id,
      Pages: null,
      PagesMenus: []
    };

    //console.log(JSON.stringify(this.jsonPages));
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    this.http.post<JsonPages>(this.url + 'api/pages/read', JSON.stringify(jsonPages), { headers: headers, params: null }).subscribe(result => {
      this.Pages = result.Pages;
      this.PagesMenus = result.PagesMenus;
    },
      error => {

      });


  }

  lang() {
    const modalRef = this.modalService.open(
      LangModalComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'message',
        windowClass: 'message'
      }
    );
    modalRef.componentInstance.lang = this.id;
    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        var newurl = "/" + result + "/" + this.id2;
        this.router.navigate([newurl])
      }
    },
      error => {

      });
  }
}
